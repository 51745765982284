@keyframes append-animate {
    from {
        transform: scaleY(0);
        opacity: 0;
    }
    to {
        transform: scaleY(1);
        opacity: 1;
    }
}

.showHeader {
    transform-origin: 50% 0;
    animation: append-animate 0.5s linear;
}
