body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir-Medium-woff-7'), url('./assets/font/Avenir-Medium-woff-7.ttf') format('ttf');
}

@font-face {
    font-family: 'Avenir-Roman';
    src: local('Avenir-Roman-8'), url('./assets/font/Avenir-Roman-8.ttf') format('ttf');
}

@font-face {
    font-family: 'Avenir-Book';
    src: local('Avenir-Book-woff-5'), url('./assets/font/Avenir-Book-woff-5.ttf') format('ttf');
}
@font-face {
    font-family: 'NotoSansArmenian-Regular';
    src: url('./assets/font/NotoSansArmenian-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'NotoSansArmenian-SemiBold';
    src: url('./assets/font/NotoSansArmenian-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'NotoSansArmenian-Bold';
    src: url('./assets/font/NotoSansArmenian-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('./assets/font/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('./assets/font/Montserrat-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('./assets/font/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/font/Montserrat-Regular.ttf') format('truetype');
}
