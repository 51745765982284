.cardFlow {
    transition-duration: 1s;
    transform: scale(1.0) translate(0, -10px);
}
.cardLeave {
    transition-duration: 1s;
    transform: scale(1.0) translate(-10, 0px);
}
#home {
    flex-shrink: 1;
}
