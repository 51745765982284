.opa {
    -webkit-mask: linear-gradient(#000, #0000) 0px -17px;
    mask: linear-gradient(#000, #0000) 0px -17px;
}
.tran {
    transform: scaleY(0);
}
.overflowAnchor {
    image-rendering: -webkit-optimize-contrast;
    overflow-anchor: none;
}
